<template>
   <section id="page-persons" class="mt-8">
      <v-card color="white" elevation="12" class="pa-2 mx-6 mb-6" outlined tile>
         <v-card-title class="align-start">
            <v-sheet color="accent" class="mt-n10" style="z-index: 1;" elevation="6" rounded>
               <v-icon dark size="52" class="pa-6">
                  mdi-account
               </v-icon>
            </v-sheet>
         </v-card-title>
         <v-card flat outlined tile class="pull-top">
            <div class="grey lighten-4 pa-6 pt-5 space-left d-flex space-between">
               <div>
                  <base-title :title="`${person.text || person.kind || 'Person'} Directory`" class="text-uppercase mt-n1" space="0" />
                  <base-subtitle title="Fill all mandatory fields marks with asterisk *." />
               </div>
               <v-spacer />
               <v-select v-model="person" :items="persons.filter(j => $hasRole(j.kind.toLowerCase(), 'R'))" label="Person Type" placeholder="Pilih Pelaku" outlined hide-details class="mt-n1" style="max-width: 300px;"
                  item-text="kind"
                  :return-object="true" :menu-props="{ bottom: true, offsetY: true }"
                  @change="changePerson"
                  >
                     <template slot="selection" slot-scope="{ item }">
                        {{ item.text || item.kind }}
                     </template>
                     <template v-slot:item="data">
                        <span :class="{ 'grey--text text--lighten-1': [].includes(data.item.kind) }">
                           {{ data.item.text || data.item.kind }}
                        </span>
                     </template>
               </v-select>
            </div>
            <template v-if="person.kind && $hasRole(person.kind.toLowerCase(), 'R')">
               <v-row v-if="isUsed">
                  <v-col cols="12" class="pt-0 d-flex">
                     <v-toolbar flat height="80">
                        <v-spacer />
                     </v-toolbar>
                  </v-col>
               </v-row>
               <v-divider />
               <div v-if="person.kind === 'Member'">
                  <n-member-form :person="person" :roles="roles" :zones="zones" @update="updateRecord" />
                  <n-member-table :person="person" :records="records" :roles="roles" :zones="zones" :imports="importRecord" @delete="deleteRecord" />
               </div>
               <div v-if="person.kind === 'Receptionist'">
                  <n-receptionist-form :person="person" :organizations="organizations" :floors="floors" @update="updateRecord" />
                  <n-receptionist-table :person="person" :organizations="organizations" :floors="floors" :records="records" @delete="deleteRecord" />
               </div>
            </template>
         </v-card>
      </v-card>
   </section>
</template>

<script>
import { flatten, encrypt } from '@/utils/nirtara'
import { member, receptionist } from '@/utils/fields.js'
export default {
   name: 'SectionPagePersons',

   components: {
      NMemberForm: () => import('@/components/person/NMemberForm'),
      NMemberTable: () => import('@/components/person/NMemberTable'),
      NReceptionistForm: () => import('@/components/person/NReceptionistForm'),
      NReceptionistTable: () => import('@/components/person/NReceptionistTable'),
   },

   data: () => ({
      isUsed: false,
      persons: [
         { kind: 'Member', id: -1 },
         { kind: 'Receptionist', id: -1 },
      ],
      person: {},
      records: [],
      roles: [],
      zones: [],
      organizations: [],
      floors: [],
   }),

   created () {
      this.$waitSetting(zone => this.$socket.on(`client/${this.$store.state.zone}/Person`, this.handleRecord))
   },
   mounted () {
      this.changePerson(this.$route.params.kind ? this.$route.params : this.persons.find(j => j.kind === 'Receptionist') || this.persons[0])
   },
   destroyed () {
      this.$socket.off(`client/${this.$store.state.zone}/Person`, this.handleRecord)
   },
   methods: {
      changePerson (json) {
         this.person = Object.assign({}, this.$hasRole(json.kind.toLowerCase(), 'R') ? json : this.persons.find(j => this.$hasRole(j.kind.toLowerCase(), 'R')) || this.persons[0])
         this.selectRecord()
      },
      async selectRecord (kind, skip) {
         !skip && (this.records = [])
         !kind && (kind = this.person.kind)
         var data = {
            conditions: { kind: kind },
            headers: { command: 'selectPerson' },
            options: { limit: skip ? 600 : 60, skip: skip || 0 },
         }
         this.$store.state.user.code === 'SPA' && (data.conditions.zone = '*')
         // console.log(`selectRecord (${kind})`, JSON.parse(JSON.stringify(data)))
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('selectRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else {
            let rows = []
            switch (kind) {
               case 'Member': this.records = response.data.map(json => Object.assign({}, member, flatten(json)))
                  this.roles = await this.selectParent({ kind: 'Roles' }, { identity: 1, name: '$object.name', code: '$object.code', zone: 1 })
                  this.zones = await this.selectParent({ kind: 'Management' }, { identity: 1, name: '$object.name', zone: 1 })
                  break
               case 'Receptionist':
                  rows = response.data.map(json => {
                     const floors = json.Person[0][json.kind].filter(j => j.Floor)
                     json.Person[0][json.kind] = json.Person[0][json.kind].filter(j => !j.Floor)
                     return Object.assign({}, receptionist, flatten(json), { Floors: floors })
                  })
                  if (skip) this.records.push(...rows)
                  else {
                     this.records = rows
                     this.organizations = await this.selectParent({ kind: 'Organization' }, { identity: 1, name: '$object.name', Floors: '$array' }, 'Subject', { limit: 100 })
                     this.floors = await this.selectParent({ kind: 'Floor' }, { identity: 1, name: '$object.name' }, 'Location')
                     // console.log(`selectRecord ( this.organizations = ${this.organizations.length} )`, JSON.parse(JSON.stringify(this.organizations)))
                     // console.log(`selectRecord ( this.floors = ${this.floors.length} )`, JSON.parse(JSON.stringify(this.floors)))
                  }
                  rows.length === (skip ? 600 : 60) && this.records.length <= (60 + 600 * 15) && this.selectRecord(kind, this.records.length)
                  break
            }
            // console.log(`selectRecord ( ${kind} = ${this.records.length} )`, JSON.parse(JSON.stringify(this.records)))
         }
      },
      selectParent (conditions, projection, type, options) {
         return new Promise((resolve) => {
            var data = {
               conditions: conditions ?? {},
               projection: projection ?? {},
               options: options ?? {},
               headers: { command: `select${type ?? 'Option'}` },
            }
            this.$store.state.user.code === 'SPA' && (data.conditions.zone = '*')
            this.$store.dispatch('mongoose', data)
            .then(response => resolve(response.data.map(json => projection ? json : flatten(json)))).catch(() => resolve([]))
         })
      },
      async updateRecord (item) {
         const images = []
         item.Card && images.push({ Card: [item.Card] }) // { Card: item.Card ? [`${item.Card.substr(0, 32)}....`] : [] },
         item.Face && images.push({ Face: [item.Face] }) // { Face: item.Face ? [`${item.Face.substr(0, 32)}....`] : [] },
         var array = []
         item.kind === 'Receptionist' && (array = item.Floors)
         var data = {
            update: {
               id: typeof item.id === 'string' ? item.id : undefined,
               site: item.site,
               kind: item.kind,
               zone: item.zone,
               identity: item.identity || '*',
               array: array,
               object: {
                  [item.kind]: images,
                  name: item.name,
                  // below added for member
                  phone: item.phone,
                  email: item.email,
                  password: item.password ? encrypt(item.password) : '',
                  role: item.role,
                  code: item.code,
                  status: item.status,
                  // below added for receptionist
                  telegram: item.telegram,
                  organization: item.organization,
               },
            },
            headers: { command: `${item.kind === 'Receptionist' && item.status === 'Active' ? 'active' : 'update'}Person` },
         }
         // console.log('updateRecord ()', '\nitem:', JSON.parse(JSON.stringify(item)), '\ndata:', JSON.parse(JSON.stringify(data)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('updateRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         response.data.Message && this.$store.commit('snackNotification', response.data)
         if (response.data.Person) {
            this.person.id = -1
            this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.kind} updated successfully` }], kind: 'Success' })
         }
      },
      async importRecord (rows, callback) {
         var data = {
            import: rows,
            headers: { command: 'importPerson' },
         }
         // console.log('importRecord (data)', JSON.parse(JSON.stringify(data)))
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('importRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else callback(Array.isArray(response.data) ? response.data.length : 0)
      },
      async deleteRecord (item) {
         var data = {
            delete: { kind: item.kind, id: item.id || '*' },
            headers: { command: 'deletePerson' },
         }
         // console.log('deleteRecord (item)', JSON.parse(JSON.stringify(item)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('deleteRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else if (response.data.Person) this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.kind} deleted successfully` }], kind: 'Success' })
         else if (Array.isArray(response.data)) this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.length} ${item.kind}s deleted successfully` }], kind: 'Success' })
      },
      handleRecord (item) {
         // console.log('handleRecord (item)', JSON.parse(JSON.stringify(item)))
         const { records, person } = this
         item.Content.forEach(record => {
            if (record.kind !== person.kind) return
            const idx = records.findIndex(j => j.id === record.id)
            idx >= 0 && records.splice(idx, 1)
            records.splice((60 + 600 * 16) - 1, records.length - 9659) // (60 + 600 * 16) = 9660
            if (['Create', 'Update', 'Import'].includes(item.action)) { //, 'Delete'
               const template = record.kind === 'Member' ? member : record.kind === 'Receptionist' ? receptionist : null
               if (record.kind === 'Receptionist') {
                  const floors = record.Person[0][record.kind].filter(j => j.Floor)
                  record.Person[0][record.kind] = record.Person[0][record.kind].filter(j => !j.Floor)
                  records.unshift(Object.assign({}, template, flatten(record), { Floors: floors }))
               } else {
                  records.unshift(Object.assign({}, template, flatten(record)))
               }
            }
         })
      },
   },
}
</script>

<style lang="scss" scoped>
.pull-top {
   margin-top: -92px;
}
.space-left {
   padding-left: 136px !important;
   height: 90px;
}
</style>
